import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import './Calendar.scss';
import {Layers, SelectedItem} from '@mappr/react-lib';
import PropTypes from 'prop-types';
import {CalendarItems} from './CalendarItems';
import {AppContext} from '../../context';
import {getHash} from '../../utils/urlUtils';
import CssFilterConverter from 'css-filter-converter';

export default function Calendar({
                                     openedControls,
                                     controlsToggle,
                                     map,
                                     setMaxZoomBackup,
                                     maxZoomBackup,
                                     selectedValues,
                                     onToggle,
                                     removeAllFilters
                                 }) {
    const {t} = useTranslation();
    const {configModal, projectConfigs, activeSecondaryLayers} = useContext(AppContext);
    const urlColorMode = getHash('color') && `#${getHash('color')}`;

    useEffect(() => {
        if (projectConfigs) {
            const {primaryColor} = projectConfigs;
            const root = document.documentElement;
            const result = CssFilterConverter.hexToFilter(urlColorMode || primaryColor);
            root?.style.setProperty(
                '--primary-color', urlColorMode || primaryColor
            );
            root?.style.setProperty(
                '--filter-svg-color', result?.color
            );
        }
    }, [projectConfigs, urlColorMode]);

    const handleCalendarClick = (deselect, toggleLayer) => {
        removeAllFilters();
        onToggle('type', 'Gare');
        if (
            openedControls === 'calendar'
        ) {
            configModal.handleSave('background-111');
            deselect();
            toggleLayer('background-111', 'basemap');
            location.href = '/';
        }
        if (openedControls === 'calendar') {
            controlsToggle('');
            map?.setMaxZoom(maxZoomBackup);
        } else {
            controlsToggle('calendar');
            setMaxZoomBackup(map?.getMaxZoom());
            map?.setMaxZoom(10.99);
        }
        map.zoomTo(9);
        setTimeout(() => {
            map.setZoom(9.9);
        }, 100);

    };

    const clearDataLayers = (toggleLayer) => {
        map.getStyle().layers.forEach(layer => {
            if (activeSecondaryLayers.includes(layer.id) && layer.id !== 'SGP_Button') { // Custom layer identification
                toggleLayer(
                    layer.id,
                    'secondary'
                );
            }
        });
    };

    return (
        <div>
            <Layers group={'basemap'}>
                {({layers, activeLayers}, {toggleLayer}) => {
                    return (
                        <SelectedItem>
                            {(
                                // eslint-disable-next-line no-empty-pattern
                                {},
                                {deselect}
                            ) => (
                                <>
                                    <div
                                        className={`calendar-btn ${
                                            openedControls ===
                                            'calendar' && 'active'
                                        }`}
                                        onClick={() => {
                                            clearDataLayers(toggleLayer);
                                            handleCalendarClick(
                                                deselect,
                                                toggleLayer
                                            );
                                        }}
                                    >
                                        <span/>
                                        <p>{t('calendar.title')}</p>
                                    </div>

                                    <CalendarItems
                                        map={map}
                                        openedControls={openedControls}
                                        toggleLayer={toggleLayer}
                                        layers={layers}
                                        activeLayers={activeLayers}
                                        onToggle={onToggle}
                                        selectedValues={selectedValues}
                                        controlsToggle={controlsToggle}
                                        closeCalendar={() =>
                                            handleCalendarClick(
                                                deselect,
                                                toggleLayer
                                            )
                                        }
                                    />
                                </>
                            )}
                        </SelectedItem>
                    );
                }}
            </Layers>

        </div>
    );
}
Calendar.propTypes = {
    openedControls: PropTypes.string,
    controlsToggle: PropTypes.func,
    map: PropTypes.object,
    setMaxZoomBackup: PropTypes.func,
    maxZoomBackup: PropTypes.number,
    removeAllFilters: PropTypes.func,
    onToggle: PropTypes.func,
    selectedValues: PropTypes.object
};
